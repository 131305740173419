import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { AccountModel } from 'app/models/account.model';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<AccountModel> = new ReplaySubject<AccountModel>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: AccountModel)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<AccountModel>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<AccountModel | null>
    {
        return null;/*this._httpClient.get<AccountModel>('api/common/user').pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );*/
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: AccountModel): Observable<AccountModel | null>
    {
        return /* this._httpClient.patch<AccountModel>('api/common/user', {user}).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        )*/;
    }
}
