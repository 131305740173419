import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { Subject, catchError, of, takeUntil } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet,
    ],
})
export class AppComponent implements OnInit,OnDestroy {
 
    authService = inject(AuthService);
    unsubscribeAll = new Subject<void>();

    ngOnInit(): void {
        this.authService.getCSRFToken().pipe(
            takeUntil(this.unsubscribeAll),
            catchError((err)=>of(err))
        ).subscribe()
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

}
