import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';
import { environment } from 'environments/environment';
import {  Observable, of } from 'rxjs';
import { Constants } from 'app/core/data/constants';

@Injectable({providedIn: 'root'})
export class AuthService
{
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _storageUtilsService = inject(StorageUtilsService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }



    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        const token = this._storageUtilsService.getAccessToken();
        return of(token  ? true : false);
    }
        /**
     * call to get csrf token
     */
    getCSRFToken(): Observable<any> {
        let headers = new HttpHeaders();
        headers= headers.append(Constants.AVOID_INTERCEPTOR_HEADER, 'true');
        return this._httpClient.get(environment.ENDPOINTS.CSRF_URL, { observe: 'response',headers });
    }
}
