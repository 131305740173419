// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    reCaptcheKey: '6LfxCaUZAAAAAMNOCJKL6mdYNf4wHtWGlC_GjXC7',
    ENDPOINTS: {
        LOGIN_URL: '/auth/login',
        LOGOUT_URL: '/auth/logout',
        CREATE_PASSWORD_URL: '/uaa/api/activate',
        ACCOUNT_URL: '/uaa/api/account',
        RESET_PASSWORD_URL: '/uaa/api/account/reset-password/init',
        REGISTER_URL: '/uaa/api/register',
        CHANGE_PASSWORD_URL: '/uaa/api/account/change-password',
        RESET_PASSWORD_FINISH_URL: '/uaa/api/account/reset-password/finish',
        INVITATION_URL: 'uaa/api/invitations',
        CSRF_URL: '/auth/hello',
        RECAPTCHA_KEY_URL: '/uaa/api/google-recaptcha-key-site',
        GUEST_REGISTER_URL: 'uaa/api/register-guest',
        FOLDER_URL: '/companymanagement/api/companies',
        COMMUNES_URL: '/companymanagement/api/communes',
        WILAYAS_URL: '/companymanagement/api/wilayas',
        LEGAL_FORMS_URL: '/companymanagement/api/legal-forms',
        PROFESSION_URL: '/companymanagement/api/professions',
        AUTH_URL: '/public/api/platforms-urls/auth',
        PLATFORM_URL: '/api/platforms-urls/all'
    },
    URLS: {
        FAST_URL : 'https://app-staging.dz-fast.com',
        RHP_URL : 'https://app-staging.rh-partner.com/',
        ATS_URL : 'https://ats-staging.rh-partner.com/',
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
