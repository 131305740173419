import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { Constants } from './core/data/constants';
import { ResetPasswordGuard } from './core/auth/guards/reset-password.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: Constants.HOME_ROUTE},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: Constants.HOME_ROUTE},
    
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: Constants.FORGOT_ROUTE, loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {
                path: Constants.RESET_PASSWORD_ROUTE, loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') ,canActivate:[ResetPasswordGuard],
            },
            {path: Constants.GUEST_REGISTER_ROUTE, loadChildren: () => import('app/modules/auth/guest-register/guest-register.routes'),canActivate:[ResetPasswordGuard]},
            {path: Constants.REGISTER_ROUTE, loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
            {path: Constants.ACTIVATE_ACCOUNT_ROUTE, loadChildren: () => import('app/modules/auth/account-activation/account-activation.routes')},
            {path: Constants.LOGIN_ROUTE, loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),}
    
        ]

    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: Constants.LOGOUT_ROUTE, loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: Constants.HOME_ROUTE, loadChildren: () => import('app/modules/admin/home/home.routes')},
        ]
    },
    
    // Errors pages 
    {
        path: '**',
        redirectTo: Constants.NOT_FOUND_ROUTE
    },
    {
        path:Constants.NOT_FOUND_ROUTE,
        loadChildren: ()=> import('app/modules/errors/error-404/error-404.routes')
    }
];
